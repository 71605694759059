@import url("https://fonts.googleapis.com/css2?family=Itim&display=swap");

// Reset css
* {
  padding: 0;
  margin: 0;
}
html,
body {
  font-family: "Itim", sans-serif;
  font-size: 16px;
  background: #000;
}
ol,
ul {
  list-style: none;
}
img {
  vertical-align: top;
  width: 100%;
  border: none;
}
a {
  color: inherit;
  text-decoration: none;
}
button {
  border: none;
  background: none;
  cursor: pointer;
}
input {
  border: none;
}
a {
  cursor: pointer;
}

// App
.container {
  margin: 0 auto;
  overflow: hidden;
  &.wrapper {
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 24px;
  }
}

.home-back {
  font-size: 28px;
  background: #e37117;
  border: 4px solid #fff;
  color: #fff;
  padding: 10px 20px;
  border-radius: 12px;
}
.page-app {
  overflow: hidden;
  .page-main {
    background-image: url(assets/images/img-bg-page.png);
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    .section-intro {
      padding-top: 54px;
      .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        .list-social {
          display: flex;
          align-items: center;
          gap: 32px;
          @media only screen and (max-width: 864px) {
            gap: 24px;
          }
          @media only screen and (max-width: 480px) {
            gap: 16px;
          }
          li {
            a {
              &:hover {
                opacity: 0.8;
              }
            }
            img {
              width: 80px;
              @media only screen and (max-width: 480px) {
                width: 40px;
              }
            }
          }
        }
        .title {
          margin-top: 26px;
          display: flex;
          align-items: center;
          gap: 18px;
          color: #fff;
          font-size: 96px;
          font-weight: 400;
          line-height: 115.2px;
          text-align: center;
          @media only screen and (max-width: 864px) {
            font-size: 56px;
          }
          @media only screen and (max-width: 668px) {
            font-size: 36px;
          }
          @media only screen and (max-width: 480px) {
            font-size: 24px;
            line-height: 48px;
          }
          img {
            width: 115px;
            @media only screen and (max-width: 668px) {
              width: 80px;
            }
            @media only screen and (max-width: 480px) {
              width: 60px;
            }
          }
        }
        .img-intro {
          margin-top: 32px;
          width: 500px;
          @media only screen and (max-width: 568px) {
            width: 380px;
          }
          @media only screen and (max-width: 480px) {
            width: 300px;
          }
        }
        .content {
          color: #fff;
          font-size: 32px;
          font-weight: 400;
          line-height: 38.4px;
          text-align: center;
          margin-top: 32px;
          max-width: 620px;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 32px;
          @media only screen and (max-width: 686px) {
            gap: 24px;
            margin-top: 24px;
            font-size: 24px;
          }
          @media only screen and (max-width: 520px) {
            gap: 14px;
            margin-top: 14px;
            font-size: 18px;
            line-height: 28.4px;
          }
          .first-content {
            max-width: 400px;
          }
          .address {
          }
        }
        .btn-groups {
          display: flex;
          align-items: center;
          gap: 32px;
          margin-top: 32px;
          text-transform: uppercase;
          @media only screen and (max-width: 864px) {
            gap: 24px;
          }
          .btn-buy {
            background: #e37117;
            border: 4px solid #fff;
            font-size: 40px;
            font-weight: 400;
            line-height: 48px;
            text-align: center;
            color: #fff;
            padding: 10px 34px;
            border-radius: 12px;
            &:hover {
              opacity: 0.8;
            }
          }
          .btn-create {
            color: #e37117;
            background: #fff;
            font-size: 40px;
            font-weight: 400;
            line-height: 48px;
            text-align: center;
            border: 4px solid #e37117;
            padding: 10px 34px;
            border-radius: 12px;
            &:hover {
              opacity: 0.8;
            }
          }
          @media only screen and (max-width: 864px) {
            .btn-create,
            .btn-buy {
              font-size: 28px;
            }
          }
          @media only screen and (max-width: 686px) {
            .btn-create,
            .btn-buy {
              font-size: 18px;
              padding: 10px 24px;
            }
          }
          @media only screen and (max-width: 480px) {
            flex-wrap: wrap;
            justify-content: center;
            gap: 14px;
            .btn-create,
            .btn-buy {
              padding: 4px 14px;
            }
          }
        }
      }
    }
    .section-about {
      .container {
        padding-top: 132px;
        display: flex;
        flex-direction: column;
        align-items: center;
        @media only screen and (max-width: 864px) {
          padding-top: 80px;
        }
        @media only screen and (max-width: 480px) {
          padding-top: 40px;
        }
        .title {
          font-size: 96px;
          font-weight: 400;
          line-height: 115.2px;
          text-align: center;
          color: #fff;
          @media only screen and (max-width: 864px) {
            font-size: 56px;
          }
          @media only screen and (max-width: 668px) {
            font-size: 36px;
          }
          @media only screen and (max-width: 480px) {
            font-size: 24px;
            line-height: 48px;
          }
        }
        .img-about {
          width: 550px;
          margin-top: 32px;
          @media only screen and (max-width: 624px) {
            width: 380px;
          }
          @media only screen and (max-width: 480px) {
            width: 300px;
          }
        }
        .content {
          max-width: 912px;
          font-size: 32px;
          font-weight: 400;
          line-height: 38.4px;
          text-align: center;
          color: #fff;
          margin-top: 32px;
          @media only screen and (max-width: 686px) {
            gap: 24px;
            margin-top: 24px;
            font-size: 24px;
          }
          @media only screen and (max-width: 520px) {
            gap: 14px;
            margin-top: 14px;
            font-size: 18px;
            line-height: 28.4px;
          }
        }
        .btn-create {
          color: #fff;
          background: #e37117;
          font-size: 40px;
          font-weight: 400;
          line-height: 48px;
          text-align: center;
          border: 4px solid #fff;
          padding: 10px 34px;
          border-radius: 12px;
          margin-top: 32px;
          text-transform: uppercase;
          &:hover {
            opacity: 0.8;
          }
          @media only screen and (max-width: 864px) {
            font-size: 28px;
          }
          @media only screen and (max-width: 686px) {
            font-size: 18px;
            padding: 10px 24px;
          }
          @media only screen and (max-width: 480px) {
            padding: 4px 14px;
          }
        }
      }
    }
    .section-tokenomics {
      .container {
        padding-top: 132px;
        @media only screen and (max-width: 864px) {
          padding-top: 80px;
        }
        @media only screen and (max-width: 480px) {
          padding-top: 40px;
        }
        .title {
          font-size: 96px;
          font-weight: 400;
          line-height: 115.2px;
          text-align: center;
          color: #fff;
          @media only screen and (max-width: 864px) {
            font-size: 56px;
            line-height: 60px;
          }
          @media only screen and (max-width: 668px) {
            font-size: 36px;
          }
          @media only screen and (max-width: 480px) {
            font-size: 24px;
            line-height: 48px;
          }
        }
        .address {
          margin-top: 32px;
          color: #fff;
          font-size: 32px;
          font-weight: 400;
          line-height: 38.4px;
          text-align: center;
          @media only screen and (max-width: 668px) {
            margin-top: 0;
            font-size: 18px;
          }
          @media only screen and (max-width: 480px) {
            line-height: 24px;
          }
        }
        .list-token {
          margin-top: 65px;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 120px;
          @media only screen and (max-width: 1124px) {
            gap: 80px;
          }
          @media only screen and (max-width: 1024px) {
            gap: 60px;
            flex-wrap: wrap;
          }
          @media only screen and (max-width: 668px) {
            gap: 24px;
          }
          li {
            width: 266px;
            position: relative;
            .content {
              display: flex;
              flex-direction: column;
              align-items: center;
              gap: 10px;
              margin-top: -40px;
              .title {
                font-size: 32px;
                font-weight: 400;
                line-height: 38.4px;
                border: 4px solid #fff;
                background: #e37117;
                border-radius: 12px;
                min-width: 266px;
                padding: 15px 0;
              }
              .value {
                color: #fff;
                font-size: 32px;
                font-weight: 400;
                line-height: 38.4px;
                text-align: center;
              }
            }
          }
        }
      }
    }
    .section-more {
      position: relative;

      .container {
        .img-gif-1 {
          z-index: 1;
          width: 243px;
          position: absolute;
          left: 0;
          top: 600px;
          transform: rotate(-25deg);
          @media only screen and (max-width: 868px) {
            width: 80px;
          }
        }
        .img-gif-2 {
          z-index: 1;
          position: absolute;
          width: 304px;
          right: 0;
          top: 250px;
          transform: rotate(25deg);
          @media only screen and (max-width: 868px) {
            width: 104px;
          }
        }
        padding-top: 132px;
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 99;
        position: relative;
        @media only screen and (max-width: 864px) {
          padding-top: 80px;
        }
        @media only screen and (max-width: 480px) {
          padding-top: 40px;
        }
        .title {
          color: #fff;
          font-size: 96px;
          font-weight: 400;
          line-height: 115.2px;
          text-align: center;
          @media only screen and (max-width: 987px) {
            font-size: 56px;
            line-height: 60px;
          }
          @media only screen and (max-width: 668px) {
            font-size: 36px;
          }
          @media only screen and (max-width: 480px) {
            font-size: 24px;
            line-height: 48px;
          }
        }
        .list-more {
          position: relative;
          z-index: 99;
          margin-top: 32px;
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          max-width: 906px;
          li {
            width: 302px;
          }
        }
        .btn-create {
          color: #fff;
          background: #e37117;
          font-size: 40px;
          font-weight: 400;
          line-height: 48px;
          text-align: center;
          border: 4px solid #fff;
          padding: 10px 34px;
          border-radius: 12px;
          margin-top: 32px;
          text-transform: uppercase;
          &:hover {
            opacity: 0.8;
          }
          @media only screen and (max-width: 864px) {
            font-size: 28px;
          }
          @media only screen and (max-width: 686px) {
            font-size: 18px;
            padding: 10px 24px;
          }
          @media only screen and (max-width: 480px) {
            padding: 4px 14px;
          }
        }
      }
    }
    .page-footer {
      .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 111px 0 50px;
        @media only screen and (max-width: 864px) {
          padding-top: 80px;
        }
        @media only screen and (max-width: 480px) {
          padding-top: 40px;
          padding-bottom: 24px;
        }
        .list-social {
          display: flex;
          align-items: center;
          gap: 32px;
          @media only screen and (max-width: 864px) {
            gap: 24px;
          }
          @media only screen and (max-width: 480px) {
            gap: 16px;
          }
          li {
            a {
              &:hover {
                opacity: 0.8;
              }
            }
            img {
              width: 80px;
              @media only screen and (max-width: 480px) {
                width: 40px;
              }
            }
          }
        }
        p {
          margin-top: 43px;
          font-size: 32px;
          font-weight: 400;
          line-height: 38.4px;
          text-align: center;
          color: #fff;
          @media only screen and (max-width: 864px) {
            font-size: 28px;
          }
          @media only screen and (max-width: 480px) {
            font-size: 18px;
          }
        }
      }
    }
  }
}
