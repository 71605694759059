.meme-maker-page {
  background-image: url(../../assets/images/img-bg-page-2.png);
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  overflow: hidden;
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 90px 0;
    @media only screen and (max-width: 776px) {
      padding: 40px 0;
    }
    .title {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 96px;
      font-weight: 400;
      line-height: 115.2px;
      text-align: center;
      color: #fff;
      @media only screen and (max-width: 1080px) {
        font-size: 65px;
      }
      @media only screen and (max-width: 864px) {
        font-size: 56px;
      }
      @media only screen and (max-width: 776px) {
        font-size: 36px;
      }
      @media only screen and (max-width: 576px) {
        font-size: 24px;
      }
      @media only screen and (max-width: 480px) {
        font-size: 14px;
        line-height: 24px;
      }
      img {
        width: 115px;
        @media only screen and (max-width: 776px) {
          width: 70px;
        }
      }
    }
  }
  .main-content {
    display: flex;
    gap: 40px;
    margin-top: 55px;
    padding: 0 24px;
    @media only screen and (max-width: 1280px) {
      flex-direction: column;
    }
    @media only screen and (max-width: 776px) {
      margin-top: 24px;
    }
    h3 {
      font-size: 32px;
      font-weight: 400;
      line-height: 38.4px;
      color: #fff;
      text-transform: uppercase;
      margin-bottom: 10px;
      @media only screen and (max-width: 720px) {
        text-align: center;
      }
    }
    .row-wrapper {
      display: flex;
      align-items: center;
      gap: 15px;
      .btn-arrow {
        padding: 28px 7px;
        border: 2px solid #e37117;
        border-radius: 12px;
        img {
          width: 24px;
        }
      }
    }
    .row {
      display: flex;
      gap: 15px;
      margin: 0 auto;
      button {
        border: 2px solid #e37117;
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80px;
        height: 80px;
        box-sizing: border-box;
        overflow: hidden;
        @media only screen and (max-width: 480px) {
          width: 60px;
          height: 60px;
        }
      }

      img {
        width: 100%;
        border-radius: 12px;
      }
      @media only screen and (max-width: 720px) {
        flex-wrap: wrap;
        max-width: 280px;
        justify-content: center;
      }
    }
    .content-wrapper {
      margin: 0 auto;
    }
    .preview-container {
      position: relative;
      width: 500px;
      height: 500px;
      border-radius: 12px;
      border: 15px solid #ffffff;
      box-sizing: border-box;
      @media only screen and (max-width: 560px) {
        width: 400px;
        height: 400px;
      }
      @media only screen and (max-width: 480px) {
        width: 320px;
        height: 320px;
      }
    }
    .preview-canvas {
      position: absolute;
      width: 100%;
      height: 100%;
    }
    .customizer {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }
    .bg-white {
      background: #fff;
    }
    .btn-groups {
      margin-top: 24px;
      display: flex;
      flex-direction: column;
      gap: 24px;
      max-width: 500px;
      @media only screen and (max-width: 560px) {
        gap: 14px;
      }
      button {
        &:first-child {
          border: 4px solid #e37117;
          border-radius: 12px;
          background: #fff;
          color: #e37117;
        }
        &:last-child {
          border: 4px solid #fff;
          border-radius: 12px;
          background: #e37117;
          color: #fff;
        }
        &:hover {
          opacity: 0.8;
        }
        padding: 10px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        font-size: 40px;
        font-weight: 400;
        line-height: 48px;
        @media only screen and (max-width: 770px) {
          font-size: 36px;
        }
        @media only screen and (max-width: 560px) {
          font-size: 24px;
        }
        @media only screen and (max-width: 480px) {
          font-size: 18px;
          line-height: 24px;
        }
        img {
          width: 32px;
        }
      }
    }
  }
}
